<template>
  <div class="testi-bg">
    <div class="row text-center" style="padding: 10px">
      <div class="col-md-12">
        <p class="h1 mt-5 testi-h1">TECHNOLOGY & SOFTWARE WE USED</p>
      </div>
    </div>
    <div class="marquee-container mt-3">
      <div class="topMarquee" :style="{ animationDuration: '1000s' }">
        <div
          v-for="(tech1, index) in duplicatedTechs1"
          :key="index"
          class="tech-box"
        >
          {{ tech1 }}
        </div>
      </div>
    </div>
    <div class="marquee-container">
      <div class="topMarquee" :style="{ animationDuration: '1050s' }">
        <div
          v-for="(tech1, index) in reversedTechs1"
          :key="index"
          class="tech-box"
        >
          {{ tech1 }}
        </div>
      </div>
    </div>
    <div class="marquee-container">
      <div class="topMarquee" :style="{ animationDuration: '1200s' }">
        <div
          v-for="(tech1, index) in duplicatedTechs1"
          :key="index"
          class="tech-box"
        >
          {{ tech1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    techs1: [
      "Angular JS",
      "Vue Js",
      "React Native/Js",
      "Flutter",
      "Laravel",
      "Prestashop",
      "JAVA Spring",
      "Kotlin",
      "Solidity",
      "AWS",
      "GCP",
      "Firebase",
      "Ali Cloud",
      "ChatGPT",
    ],
    duplicatedTechs1: [],
    reversedTechs1: [],
    resetArrays: false,
  }),

  mounted() {
    this.duplicateTechs1();
  },
  methods: {
    duplicateTechs1() {
      this.duplicatedTechs1 = [
        ...this.techs1,
        ...this.techs1,
        ...this.techs1,
        ...this.techs1,
        ...this.techs1,
        ...this.techs1,
      ];
      this.reversedTechs1 = [
        ...this.techs1,
        ...this.techs1,
        ...this.techs1,
        ...this.techs1,
        ...this.techs1,
        ...this.techs1,
      ].reverse();
    },

    // handleAnimationIteration() {
    //   this.duplicatedTechs1 = [...this.duplicatedTechs1, ...this.techs1];
    //    this.reversedTechs1 = [...this.reversedTechs1, ...this.techs1];
    // },
  },
  watch: {},
};
</script>

<style scoped>
.marquee-container {
  overflow: hidden;
  width: 100%;
}

.topMarquee {
  white-space: nowrap;
  display: inline-block;
  animation: marqueeAnimation 100s linear infinite;
  width: max-content;
}

@keyframes marqueeAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.tech-box {
  background: #ffffff1a;
  color: #fff;
  font-size: clamp(14px, 3vw, 20px);
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  padding: 15px 10px;
  width: 220px;
  display: inline-block;
  margin: 5px;
  border-radius: 3px;
}
.tech-img {
  max-width: 200px;
  padding: 10px;
  height: 120px;
}

.testi-bg {
  background: linear-gradient(96.44deg, #1f2c64 21.37%, #12172f 68.12%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 40px;
}

.testi-h1 {
  font-style: normal;
  font-weight: 800;
  font-size: clamp(32px, 5vw, 50px);
  line-height: clamp(40px, 5vw, 73px);
  text-align: center;

  color: #ffffff;
}

.tech-each {
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 66px;
  text-align: center;
  margin-top: 10px;
  padding: 5px;

  color: #ffffff;
}

.tech-card-dark {
  border: #000 5px solid;
  border-radius: 10px;
  width: 100px;
}

.tech-card-white {
  border: #ffffff 5px solid;
  border-radius: 10px;
  padding: 3px;
  margin-left: 10px;
  margin-top: 15px;
  background-color: #182046;
}

.testi-description {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
}

.test-card {
  opacity: 0.5;
}

.tech-section {
  margin-bottom: 55px;
}

.card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 23.1598px;
  line-height: 28px;
  /* identical to box height */

  text-align: center;
  color: #000;
}

.card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 15.0539px;
  line-height: 23px;
  /* or 150% */

  color: #000;
}

@media only screen and (max-width: 550px) {
  .tech-box {
    max-width: 160px;
    width: 100%;
    padding: 10px 10px;
  }
}
</style>
